<template>
  <svg viewBox="0 0 20 20">
    <path
      d="M10.0003 18.3337C5.39783 18.3337 1.66699 14.6028 1.66699 10.0003C1.66699 5.39783 5.39783 1.66699 10.0003 1.66699C14.6028 1.66699 18.3337 5.39783 18.3337 10.0003C18.3337 14.6028 14.6028 18.3337 10.0003 18.3337ZM9.16699 12.5003V14.167H10.8337V12.5003H9.16699ZM9.16699 5.83366V10.8337H10.8337V5.83366H9.16699Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ErrorIcon",
};
</script>
